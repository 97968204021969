import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M0 5400 l0 -5400 5400 0 5400 0 0 5400 0 5400 -5400 0 -5400 0 0
-5400z m5140 2290 c45 -12 100 -58 124 -104 19 -35 19 -37 1 -91 -11 -30 -23
-55 -27 -55 -4 0 -8 -6 -8 -13 0 -19 -50 -77 -81 -93 -74 -38 -191 -17 -237
41 -11 14 -25 25 -31 25 -6 0 -14 18 -17 41 -5 27 -10 36 -15 27 -22 -34 3 78
26 117 55 94 158 135 265 105z m-2551 -310 c46 -21 82 -56 108 -104 16 -31 12
-99 -8 -123 -6 -7 -9 -18 -7 -25 4 -12 -30 -46 -89 -86 -37 -26 -122 -30 -175
-8 -50 21 -104 81 -118 133 -17 60 1 115 55 169 66 64 153 81 234 44z m5140 0
c38 -18 62 -38 101 -87 14 -17 29 -93 19 -93 -5 0 -9 -11 -9 -24 0 -13 -16
-44 -36 -70 -75 -99 -191 -119 -283 -48 -84 63 -108 132 -73 212 24 54 52 82
112 109 57 26 113 26 169 1z m-2437 -52 c-17 -42 -72 -101 -118 -126 -39 -21
-110 -26 -161 -11 -45 12 -113 61 -113 81 0 5 9 0 21 -11 11 -10 35 -22 52
-26 39 -8 147 -6 162 3 5 3 18 6 28 7 20 0 116 74 117 91 0 8 20 34 26 34 1 0
-5 -19 -14 -42z m-62 -633 c293 -549 396 -742 465 -880 28 -55 53 -102 56
-105 3 -3 19 -35 36 -72 l31 -66 -24 -23 c-21 -19 -22 -24 -9 -31 9 -5 15 -23
15 -41 0 -18 5 -38 10 -43 8 -8 6 -15 -5 -24 -21 -18 -19 -33 5 -25 11 3 20 5
20 3 0 -3 -27 -33 -60 -67 -33 -35 -60 -67 -60 -72 0 -5 -4 -9 -9 -9 -9 0 -39
51 -168 280 -36 63 -85 150 -109 192 -24 43 -57 101 -73 130 -16 29 -52 94
-80 143 -28 50 -81 145 -117 213 -37 67 -72 122 -78 122 -7 0 -24 -21 -38 -47
-14 -27 -67 -122 -118 -213 -51 -91 -108 -194 -128 -230 -19 -36 -52 -94 -72
-130 -20 -36 -51 -90 -67 -120 -17 -30 -50 -89 -73 -130 -23 -41 -58 -104 -77
-140 -20 -36 -39 -69 -43 -73 -7 -8 -191 182 -199 204 -3 9 137 294 159 324 4
6 38 69 75 140 38 72 71 135 75 140 4 6 40 73 80 150 40 77 76 145 80 150 4 6
36 64 70 130 35 66 79 147 97 180 18 33 65 120 104 193 l71 134 17 -29 c10
-15 73 -131 141 -258z m2443 238 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0
35 -2 23 -4z m-4970 -255 c166 -176 318 -341 416 -452 15 -16 34 -37 44 -47
18 -20 9 -27 -11 -7 -16 16 -28 2 -13 -16 14 -17 20 -151 11 -256 -6 -69 -5
-78 17 -107 13 -18 20 -33 15 -33 -9 0 -345 335 -350 350 -8 22 -29 8 -39 -27
-25 -85 -52 -170 -308 -973 -15 -47 -34 -105 -43 -129 l-16 -44 -23 29 c-12
16 -20 37 -17 46 2 9 -1 18 -8 21 -7 2 -5 3 5 2 15 -3 17 7 17 80 0 47 -5 87
-10 90 -6 4 -8 21 -5 38 3 18 7 109 8 202 2 94 1 158 -1 143 -2 -16 -8 -28
-14 -28 -10 0 -8 23 8 78 10 34 -14 27 -31 -10 -8 -18 -12 -23 -9 -11 57 232
251 996 263 1038 5 17 12 42 15 58 4 15 9 27 13 27 4 0 34 -28 66 -62z m4817
56 c0 -3 34 -133 76 -287 91 -338 100 -373 158 -597 24 -96 52 -202 61 -235
46 -169 115 -447 115 -463 0 -17 -205 -230 -214 -221 -2 2 -8 20 -14 39 -6 19
-22 71 -35 115 -39 125 -136 444 -183 600 -14 50 -32 108 -39 130 -7 22 -23
74 -34 115 -12 41 -29 98 -38 127 -16 53 -25 261 -10 266 4 1 8 34 10 74 2 40
6 75 10 79 11 12 8 64 -4 64 -6 0 -9 7 -5 15 3 8 1 15 -4 15 -6 0 -10 -6 -10
-13 0 -15 -37 -53 -45 -46 -2 3 19 32 48 65 29 33 72 83 96 112 40 47 61 63
61 46z m-6649 -225 c86 -29 153 -121 130 -177 -17 -40 -92 -120 -124 -132 -83
-30 -174 1 -226 79 -29 44 -32 54 -26 92 13 75 102 144 191 148 12 1 36 -4 55
-10z m8552 -16 c77 -40 127 -140 90 -182 -10 -12 -29 -34 -43 -51 -30 -36
-100 -70 -146 -70 -92 0 -184 84 -184 169 0 55 75 135 143 152 41 10 101 3
140 -18z m-8429 -315 c-26 -46 -50 -70 -89 -89 -36 -18 -55 -21 -108 -17 -55
5 -70 10 -101 38 -45 41 -47 59 -3 28 27 -20 44 -23 112 -22 98 0 104 2 152
42 42 35 48 38 37 20z m8508 -15 c-49 -68 -94 -93 -169 -93 -58 0 -122 30
-152 71 l-23 31 24 -12 c12 -7 31 -19 40 -27 19 -17 176 -31 186 -17 4 5 23
18 42 28 19 11 39 25 43 33 4 7 13 13 18 13 6 0 1 -12 -9 -27z m-2706 -213 c0
-20 -7 -25 -19 -13 -4 3 -2 12 4 20 15 17 15 17 15 -7z m-6 -40 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-26 -73 c-4 -17
-8 -79 -8 -137 -1 -94 -3 -105 -18 -102 -21 3 -21 -1 -4 -48 l13 -35 -96 -105
c-52 -58 -129 -141 -171 -185 -41 -44 -127 -136 -190 -205 -63 -69 -151 -163
-195 -209 -44 -47 -89 -94 -100 -106 -11 -12 -51 -55 -90 -96 -38 -41 -123
-132 -189 -202 -65 -69 -122 -124 -126 -122 -5 3 -18 24 -30 47 -32 62 -416
763 -457 836 -13 23 -27 42 -31 42 -4 0 -23 -30 -42 -68 -19 -37 -60 -112 -91
-167 -31 -55 -109 -197 -174 -315 -64 -118 -138 -253 -163 -298 l-47 -84 -54
59 c-30 32 -106 114 -170 183 -64 68 -141 151 -171 183 -82 88 -98 105 -185
201 -44 49 -93 103 -110 121 -16 18 -43 47 -58 65 -27 30 -27 31 -6 25 19 -6
21 -5 14 11 -38 90 -40 101 -26 165 9 45 10 64 2 67 -6 2 -11 21 -11 43 0 37
1 39 32 39 31 0 31 1 15 18 -15 14 -19 15 -27 3 -7 -12 -11 -12 -24 -2 -20 17
-21 35 -1 27 17 -6 21 9 5 19 -5 3 -15 16 -21 28 -6 12 48 -39 121 -113 190
-193 511 -526 564 -585 40 -44 47 -48 59 -35 8 8 47 78 87 155 40 77 91 174
113 215 21 41 66 127 100 190 33 63 85 162 116 220 31 58 76 145 101 193 25
48 50 85 55 84 6 -2 33 -46 60 -98 28 -52 139 -261 247 -465 237 -446 259
-487 268 -497 6 -7 53 39 365 367 94 98 190 200 215 226 336 351 465 480 477
475 9 -3 13 0 10 7 -2 7 3 18 12 25 24 17 45 -18 35 -60z m-3587 -3 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-119 -288 c56 -57 102 -107
102 -112 0 -8 -89 -251 -207 -564 -98 -260 -235 -600 -242 -600 -19 0 -72 62
-100 117 -17 34 -35 64 -41 68 -6 3 -7 18 -3 33 5 23 4 25 -8 15 -8 -7 -20 -8
-28 -4 -10 6 -8 9 6 13 12 3 22 22 31 54 13 49 94 287 224 659 111 319 151
425 158 425 3 0 52 -47 108 -104z m4180 17 c17 -49 54 -153 82 -233 29 -80
103 -295 166 -479 62 -183 115 -335 118 -338 2 -3 13 0 24 6 32 17 37 13 15
-11 -12 -13 -24 -42 -28 -65 -5 -32 -11 -43 -25 -43 -17 0 -33 -28 -44 -80 -3
-14 -15 -35 -26 -48 -12 -12 -18 -26 -15 -31 3 -5 1 -12 -4 -16 -6 -3 -8 -12
-5 -19 3 -7 -1 -18 -9 -24 -11 -9 -17 -3 -32 31 -10 23 -26 61 -37 85 -21 48
-173 441 -219 567 -16 44 -36 96 -43 115 -8 19 -38 98 -66 175 -28 77 -63 170
-77 207 -16 44 -21 73 -16 83 11 20 194 205 203 205 4 0 20 -39 38 -87z
m-5908 25 c0 -13 -5 -9 -31 19 l-24 26 27 -19 c15 -10 28 -22 28 -26z m41 -25
c8 -9 75 -68 149 -132 132 -114 317 -282 425 -384 30 -29 70 -67 89 -85 20
-19 36 -40 36 -48 0 -9 4 -12 10 -9 5 3 7 0 4 -8 -3 -8 -1 -18 5 -22 6 -3 11
-15 11 -27 0 -11 8 -22 18 -24 17 -5 42 -46 42 -70 0 -7 6 -17 13 -21 14 -8
28 -32 68 -118 13 -27 27 -51 32 -53 4 -2 7 -9 7 -16 0 -7 9 -21 21 -32 14
-13 18 -25 14 -44 -4 -19 -3 -21 5 -10 7 11 10 12 10 2 0 -8 10 -22 23 -33
l22 -18 -24 -1 c-24 0 -91 55 -340 279 -262 235 -402 373 -391 386 8 9 6 14
-5 19 -9 3 -13 11 -9 17 4 7 3 9 -4 5 -15 -9 -52 33 -52 61 0 13 -4 23 -8 23
-8 0 -15 15 -60 123 -8 20 -21 37 -28 37 -7 0 -10 8 -7 18 3 12 -4 25 -22 36
-14 9 -23 21 -20 25 3 5 -2 12 -11 15 -12 4 -14 14 -9 36 5 24 3 30 -10 30 -9
0 -13 4 -10 10 3 5 0 7 -8 4 -8 -3 -18 -1 -22 5 -3 6 1 11 11 11 13 0 14 3 4
15 -7 8 -8 15 -2 15 5 0 16 -7 23 -17z m2364 7 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m3045 -9 c0 -12 -20 -25 -27 -18 -7 7
6 27 18 27 5 0 9 -4 9 -9z m-3055 -31 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10
-3 6 -2 10 4 10 5 0 13 -4 16 -10z m2090 0 c3 -5 -1 -10 -9 -10 -9 0 -16 5
-16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m2806 -134 c-9 -11 -9 -16 0 -21 8 -6
7 -10 -5 -17 -9 -5 -16 -20 -16 -33 0 -33 -16 -71 -40 -95 -11 -11 -20 -31
-20 -43 0 -13 -13 -44 -29 -68 -22 -33 -27 -47 -20 -61 7 -14 6 -18 -6 -18 -8
0 -16 -4 -16 -10 -1 -5 -2 -14 -3 -19 -2 -13 -70 -83 -176 -181 -51 -47 -106
-98 -124 -115 -43 -41 -110 -101 -181 -160 -33 -27 -80 -69 -106 -92 -25 -24
-52 -43 -60 -43 -7 0 1 13 18 28 30 29 49 66 45 90 -2 6 2 12 8 12 6 0 9 4 6
9 -9 15 5 41 21 41 10 0 14 7 10 20 -3 11 -1 20 4 20 5 0 9 7 9 15 0 9 11 32
23 53 25 39 26 43 30 105 1 25 8 39 20 43 9 3 17 12 17 21 0 10 6 13 16 9 9
-3 12 -2 8 5 -3 6 25 40 62 76 149 141 405 369 484 432 27 21 40 20 21 -3z
m-4866 -222 c0 -17 -3 -20 -12 -12 -7 6 -13 17 -13 25 0 8 -4 11 -10 8 -5 -3
-10 0 -10 8 0 12 5 13 23 5 13 -7 22 -20 22 -34z m-375 26 c0 -5 -8 -10 -17
-10 -15 0 -16 2 -3 10 19 12 20 12 20 0z m-38 -42 c-7 -7 -12 -8 -12 -2 0 14
12 26 19 19 2 -3 -1 -11 -7 -17z m84 -9 c13 -12 20 -28 17 -38 -4 -13 -3 -13
5 -2 8 11 18 4 48 -30 22 -24 55 -59 75 -79 20 -19 74 -76 120 -125 46 -50
143 -153 215 -230 72 -77 142 -152 155 -166 13 -15 70 -75 126 -135 56 -60
110 -119 121 -131 18 -21 176 -191 275 -297 56 -59 40 -71 133 104 81 155 166
314 230 435 29 55 68 129 87 163 18 35 37 64 42 64 8 0 20 -22 172 -312 42
-80 90 -170 106 -200 16 -30 57 -107 90 -170 33 -63 64 -119 69 -125 5 -6 26
9 55 40 37 41 211 227 288 310 11 12 49 52 85 91 36 38 131 139 211 224 80 85
169 180 198 210 32 35 51 49 51 38 0 -9 -8 -25 -17 -34 -17 -17 -17 -18 0 -11
11 4 17 2 17 -8 0 -8 4 -15 8 -15 30 0 34 -60 32 -506 -1 -427 0 -453 17 -457
10 -2 0 -2 -22 -1 -36 4 -37 3 -17 -8 31 -16 27 -28 -10 -30 -29 -1 -28 -2 12
-9 51 -9 -255 -3 -560 11 -274 13 -1247 12 -1540 0 -263 -11 -666 -36 -839
-51 -63 -5 -116 -7 -119 -4 -3 3 1 5 9 5 26 0 41 17 46 54 3 24 1 36 -8 36 -9
0 -10 101 -3 478 4 262 7 547 6 633 -2 132 0 158 13 169 11 9 12 15 5 20 -6 4
-12 20 -14 36 -1 16 -8 39 -14 52 -16 28 -5 28 24 1z m-1833 -53 c4 -10 1 -13
-9 -9 -15 6 -19 23 -5 23 5 0 11 -7 14 -14z m1764 -43 c-3 -10 -5 -4 -5 12 0
17 2 24 5 18 2 -7 2 -21 0 -30z m3113 -193 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-16 -36 c22 -9 10 -23 -14 -17 -11 3 -17
9 -14 14 6 11 8 11 28 3z m565 -156 c10 -30 9 -31 -4 -14 -8 11 -15 26 -15 33
0 22 8 14 19 -19z m112 -273 c82 -191 204 -488 282 -687 23 -59 47 -108 52
-108 6 0 44 35 86 78 127 128 158 156 165 149 4 -4 -1 -16 -11 -27 -10 -11
-13 -20 -8 -20 18 0 36 29 30 50 -3 11 0 23 5 27 7 3 8 1 4 -5 -4 -7 -2 -12 5
-12 6 0 9 -8 6 -20 -3 -11 -2 -22 2 -24 4 -3 8 -53 9 -113 1 -59 4 -140 6
-180 6 -78 0 -91 -30 -68 -63 49 -172 83 -445 140 -123 26 -226 48 -230 51 -4
2 -5 14 -1 26 4 16 2 20 -10 16 -11 -5 -13 1 -11 21 11 91 21 466 13 469 -6 2
-10 60 -10 135 0 89 3 127 10 117 7 -11 9 -3 7 27 -1 24 -5 43 -9 43 -5 0 -8
5 -8 11 0 5 5 7 10 4 7 -4 9 3 5 19 -3 14 -8 38 -11 53 -3 16 -3 26 0 23 2 -3
42 -91 87 -195z m-4733 -26 c31 -38 14 -38 -20 1 -14 17 -21 30 -15 29 7 0 22
-13 35 -30z m-128 -349 c146 -150 300 -309 344 -354 l78 -81 13 37 c7 20 20
47 28 60 8 12 23 44 32 71 25 66 29 53 7 -20 -18 -57 -19 -128 -2 -118 5 3 11
1 15 -5 3 -5 -1 -10 -9 -10 -8 0 -17 -4 -20 -9 -3 -4 14 -7 37 -5 47 3 39 -1
-68 -31 -33 -9 -69 -20 -80 -25 -11 -5 -32 -11 -48 -14 -50 -11 -136 -57 -182
-98 -25 -22 -47 -39 -49 -37 -4 5 -165 317 -268 518 -43 84 -81 159 -85 165
-4 6 -6 24 -5 41 1 16 -1 24 -4 17 -7 -16 -44 34 -43 58 0 13 1 13 6 0 4 -8
13 -21 21 -29 13 -13 14 -12 7 9 -4 14 -11 29 -16 35 -12 12 -11 45 1 45 6 0
10 -5 10 -11 0 -8 5 -8 15 1 8 7 12 17 8 23 -5 8 -8 7 -11 -1 -2 -7 -8 -9 -13
-6 -6 3 -6 11 2 20 9 11 8 15 -5 20 -9 3 -16 10 -16 15 0 23 49 -24 300 -281z
m424 205 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4
-15z m5081 -345 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0
7 -4 4 -10z m-4984 -187 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6
-23z m3104 -122 c313 -24 311 -23 288 -34 -23 -10 -27 -25 -23 -81 l2 -29 -94
7 c-51 3 -309 11 -573 17 -483 11 -783 6 -1466 -23 -204 -8 -280 -8 -295 0
-18 11 -18 11 4 12 15 0 22 6 22 20 0 11 -6 20 -12 20 -8 0 -9 3 -3 8 19 14
15 32 -7 32 -12 0 -19 3 -16 6 15 14 310 35 818 58 149 7 1228 -4 1355 -13z
m955 -76 c157 -18 336 -52 370 -70 l25 -14 -24 -1 c-24 0 -24 0 -5 -14 18 -14
18 -15 2 -21 -26 -10 -22 -27 5 -20 15 4 18 3 9 -3 -8 -5 -13 -13 -13 -18 2
-26 -101 -19 -384 27 -179 28 -174 26 -170 55 1 16 -3 24 -14 25 -12 0 -11 2
4 9 17 7 17 9 3 9 -18 1 -25 31 -11 44 10 11 66 8 203 -8z m-3629 -28 c32 -17
33 -31 5 -47 -24 -12 -24 -12 -4 -32 11 -11 17 -22 15 -25 -3 -3 -131 -31
-284 -63 -153 -33 -294 -64 -313 -69 -19 -6 -38 -11 -41 -11 -12 0 -5 29 7 34
8 3 11 12 8 20 -3 9 2 16 13 19 15 3 13 6 -9 15 -16 6 -28 16 -28 21 0 30 322
114 555 144 17 2 35 5 41 6 7 0 22 -5 35 -12z m3012 -34 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z m1012 -123 c3 -5 -1 -10 -9 -10 -9 0 -16 5
-16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-1340 -170 c94 -5 190 -13 214 -16
43 -7 43 -7 38 -41 -5 -26 -2 -36 11 -41 13 -5 10 -8 -13 -13 l-30 -7 30 -2
c17 -1 39 -6 50 -11 13 -5 -36 -7 -130 -3 -288 11 -863 21 -1041 18 -579 -9
-836 -16 -1064 -29 -272 -16 -313 -17 -265 -6 17 3 30 11 30 16 0 6 4 15 8 22
5 7 -1 13 -15 16 -13 2 -4 5 20 6 23 0 42 6 42 12 0 6 -11 8 -28 4 -16 -3 -39
-1 -53 4 -24 10 -24 10 6 11 25 1 27 3 10 9 -15 6 -11 9 20 14 76 13 434 38
666 47 283 10 1258 4 1494 -10z m943 -75 c156 -18 358 -57 375 -74 8 -8 7 -11
-6 -11 -9 0 -17 -4 -17 -10 0 -5 5 -10 10 -10 6 0 10 -18 10 -40 0 -24 5 -40
12 -40 6 0 9 -2 6 -5 -3 -3 -65 4 -139 15 -363 56 -454 72 -460 78 -4 4 -4 24
-2 45 4 25 10 37 22 37 13 1 13 3 -3 15 -19 14 -17 15 18 15 20 0 99 -7 174
-15z m-3608 -22 c-23 -9 -31 -26 -25 -52 4 -15 1 -27 -7 -32 -9 -6 -4 -9 15
-9 15 0 27 -4 27 -9 0 -10 -7 -12 -320 -77 -63 -13 -155 -33 -205 -45 -49 -11
-100 -23 -112 -26 -21 -5 -23 -2 -23 33 0 23 4 34 8 27 6 -10 12 -10 28 2 16
13 17 16 4 25 -8 5 -23 10 -33 10 -67 0 102 64 275 104 120 28 286 54 348 54
19 0 28 -2 20 -5z m2190 -133 c1039 -32 1747 -104 2074 -211 88 -28 153 -58
130 -59 -6 0 -29 6 -51 14 -122 43 -462 101 -768 130 -88 9 -182 18 -210 21
-84 8 -486 34 -740 47 -504 26 -1484 14 -2137 -27 -128 -8 -289 -17 -358 -19
l-125 -5 90 14 c239 37 826 78 1350 94 386 12 393 12 745 1z m-2203 -116 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-752 -357 c82 -41 337
-115 488 -143 21 -3 36 -8 33 -11 -7 -8 -197 27 -312 57 -151 40 -262 93 -289
140 -7 12 -4 12 11 -1 10 -9 42 -28 69 -42z m4762 -143 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-137 -25 c-161 -29 -318 -48 -437 -53 l-108
-5 125 14 c173 20 434 56 439 61 2 2 23 4 45 3 29 -1 9 -7 -64 -20z m-6773
-1214 c8 -22 28 -76 45 -120 16 -44 36 -96 43 -115 7 -19 20 -53 28 -75 9 -22
30 -77 48 -122 17 -46 33 -83 34 -83 2 0 13 30 25 68 13 37 28 81 35 97 7 17
17 43 24 58 18 44 14 70 -29 183 -39 100 -40 107 -23 120 22 16 73 38 76 33 2
-2 29 -74 61 -159 105 -280 149 -390 156 -390 4 0 30 89 59 198 80 300 95 347
114 350 25 5 22 -22 -17 -156 -83 -284 -127 -452 -122 -461 5 -8 -82 -81 -96
-81 -3 0 -34 79 -69 175 -35 96 -67 175 -70 175 -9 0 -80 -206 -86 -250 -4
-27 -16 -43 -50 -68 -24 -17 -47 -32 -51 -32 -4 0 -17 28 -29 63 -12 34 -32
91 -46 127 -14 36 -59 156 -100 268 l-75 204 41 18 c23 10 46 18 51 16 5 -1
15 -20 23 -41z m2463 -95 l0 -140 150 0 150 0 0 125 0 125 36 15 c67 28 63 45
64 -288 0 -166 -2 -316 -6 -332 -4 -22 -16 -35 -43 -48 -21 -9 -41 -17 -44
-17 -4 0 -7 79 -7 175 0 96 -3 175 -7 175 -5 0 -21 -9 -38 -20 -25 -17 -47
-20 -142 -20 l-113 0 0 -135 0 -135 -40 -20 c-21 -11 -44 -20 -50 -20 -7 0 -9
105 -8 332 l3 333 35 17 c61 30 60 31 60 -122z m2180 -80 l0 -220 27 11 c68
26 123 90 123 143 0 25 10 32 103 74 13 6 7 -49 -9 -81 -8 -18 -40 -52 -70
-76 l-55 -44 29 -19 c17 -10 49 -48 72 -84 24 -35 55 -75 71 -88 l27 -23 -37
-37 c-20 -20 -42 -36 -48 -36 -14 0 -62 60 -123 153 -66 99 -118 106 -106 15
4 -28 4 -69 0 -93 -5 -39 -10 -44 -48 -59 -23 -9 -45 -16 -49 -16 -4 0 -6 151
-5 337 l3 336 35 13 c63 23 60 34 60 -206z m1790 -115 l0 -335 -36 -15 c-51
-21 -64 -19 -64 10 0 30 -4 31 -37 5 -74 -58 -174 -43 -250 37 -33 34 -64 115
-62 162 2 67 62 162 135 212 42 28 57 33 109 34 33 0 70 -4 83 -8 22 -8 22 -8
22 93 l0 101 43 19 c23 10 45 19 50 19 4 1 7 -150 7 -334z m742 178 l3 -88
103 -3 104 -3 -68 -36 c-38 -20 -84 -36 -104 -37 l-35 -1 -3 -110 c-3 -116 6
-155 42 -180 38 -27 110 -12 138 29 15 22 19 -4 5 -31 -33 -63 -105 -100 -166
-83 -87 23 -105 60 -111 235 l-5 140 -42 0 c-24 0 -44 3 -45 6 -4 8 67 69 80
69 7 0 12 30 14 77 l3 76 35 13 c19 7 38 13 42 13 4 1 9 -38 10 -86z m-6263
-238 c41 -97 77 -172 81 -168 4 4 20 45 34 90 l27 82 -24 61 -25 61 39 24 c21
14 40 25 42 25 2 0 37 -79 77 -175 40 -97 77 -172 81 -168 4 4 23 62 44 128
59 193 69 215 84 205 16 -9 17 -4 -69 -268 -21 -67 -37 -128 -34 -136 3 -8
-12 -24 -41 -41 -26 -15 -48 -25 -49 -23 -2 1 -23 55 -48 118 -25 63 -50 116
-55 117 -9 3 -63 -147 -63 -177 -1 -13 -61 -60 -78 -60 -4 0 -46 97 -93 216
l-87 215 37 24 c20 13 38 24 41 24 3 1 39 -78 79 -174z m629 148 c15 -41 96
-231 119 -282 24 -51 25 -50 63 66 l23 72 -21 52 c-12 28 -22 57 -22 64 0 12
69 60 75 53 2 -2 22 -48 45 -103 75 -177 108 -247 112 -238 6 9 43 125 83 255
20 65 30 85 41 81 8 -3 14 -10 14 -15 0 -6 -27 -93 -60 -195 -33 -101 -60
-191 -60 -201 -1 -15 -60 -62 -78 -62 -4 0 -29 57 -56 125 -27 69 -53 122 -57
118 -9 -10 -59 -163 -59 -183 0 -14 -60 -60 -78 -60 -4 0 -22 37 -39 83 -18
45 -55 138 -83 206 -27 68 -50 129 -50 136 0 12 56 54 72 55 3 0 11 -12 16
-27z m1690 11 c20 -8 49 -27 65 -41 l27 -27 0 -154 c0 -85 5 -162 10 -172 9
-17 4 -23 -31 -45 -50 -32 -69 -27 -69 20 0 42 -8 44 -41 9 -58 -62 -140 -73
-205 -26 -54 39 -65 55 -71 99 -10 81 65 167 171 195 40 11 54 10 92 -4 24 -8
46 -12 49 -8 8 14 -12 102 -28 117 -8 8 -33 17 -55 20 -34 5 -44 1 -66 -20
-14 -14 -26 -34 -26 -45 0 -13 -16 -25 -56 -41 -30 -13 -57 -21 -59 -19 -11
11 20 85 44 105 57 50 177 68 249 37z m482 1 c32 -17 57 -53 43 -64 -4 -5 -25
-18 -45 -30 l-37 -22 -12 23 c-7 13 -17 26 -23 30 -22 15 -65 8 -91 -15 l-27
-23 1 -155 c2 -170 3 -166 -61 -192 l-38 -16 0 218 0 219 40 16 c22 9 42 16
45 16 2 0 5 -19 7 -42 l3 -43 25 27 c34 35 90 68 118 68 13 0 36 -7 52 -15z
m210 -31 l0 -46 33 32 c39 39 66 50 120 50 45 0 77 -20 96 -58 l12 -23 36 31
c44 39 72 50 128 50 91 0 114 -49 115 -251 0 -161 -3 -171 -56 -197 -25 -12
-47 -22 -49 -22 -3 0 -5 78 -5 173 0 205 -5 221 -69 225 -34 3 -49 -2 -67 -19
-23 -22 -24 -27 -24 -180 l0 -157 -42 -18 c-24 -10 -46 -20 -50 -22 -5 -2 -8
65 -8 150 0 159 -9 225 -32 239 -24 15 -92 9 -115 -10 -21 -18 -23 -27 -25
-181 l-3 -162 -50 -19 c-27 -10 -51 -19 -52 -19 -6 0 -2 377 3 411 5 28 13 38
42 52 57 25 62 22 62 -29z m1373 33 c18 -8 46 -26 64 -41 l32 -27 3 -162 c2
-89 6 -169 11 -177 5 -11 -4 -21 -35 -38 -60 -31 -68 -29 -68 18 0 40 -12 52
-27 27 -4 -7 -23 -25 -41 -40 -27 -22 -44 -27 -85 -27 -45 0 -59 5 -101 38
-53 40 -70 75 -60 128 10 52 40 91 96 123 67 39 123 48 170 28 40 -17 48 -14
48 18 0 66 -45 115 -105 115 -30 0 -75 -41 -75 -68 0 -14 -14 -25 -47 -38 -27
-11 -51 -20 -55 -22 -12 -5 -10 44 3 70 12 27 64 65 105 78 45 13 131 11 167
-3z m1520 0 c18 -8 46 -26 64 -41 l32 -27 3 -162 c2 -89 6 -169 11 -177 5 -11
-4 -21 -35 -38 -60 -31 -68 -29 -68 18 0 40 -12 52 -27 27 -4 -7 -23 -25 -41
-40 -48 -39 -123 -38 -178 1 -58 42 -68 59 -68 111 0 64 28 109 93 148 67 40
125 50 173 30 20 -9 39 -13 42 -9 8 7 -2 71 -16 99 -32 66 -158 44 -158 -28 0
-10 -18 -23 -47 -35 -27 -11 -51 -20 -55 -22 -12 -6 -9 47 4 71 38 72 182 111
271 74z m932 -6 c80 -37 84 -46 88 -226 2 -88 7 -167 11 -175 4 -11 -7 -22
-38 -39 -52 -27 -66 -22 -66 25 0 19 -3 34 -6 34 -4 0 -26 -18 -50 -40 -40
-36 -48 -40 -97 -40 -43 0 -62 6 -93 28 -58 42 -68 59 -68 110 1 63 26 107 82
143 82 52 135 58 215 24 13 -6 17 -2 17 16 0 53 -21 99 -52 115 -55 29 -128
-4 -128 -57 0 -10 -18 -23 -47 -35 -27 -11 -51 -20 -55 -22 -5 -2 -8 10 -8 27
0 41 49 95 103 115 59 20 144 19 192 -3z m688 5 c36 -14 87 -69 87 -91 0 -13
-66 -65 -82 -65 -5 0 -8 5 -8 12 0 20 -43 76 -68 87 -53 25 -121 2 -147 -49
-8 -16 -15 -55 -15 -88 0 -102 60 -168 163 -179 57 -6 100 9 144 50 13 11 25
18 28 15 9 -9 -58 -100 -97 -131 -67 -53 -182 -36 -247 38 -49 57 -64 100 -58
177 6 75 30 128 79 175 62 59 150 79 221 49z m494 -10 c113 -53 157 -187 105
-316 -12 -31 -88 -102 -132 -125 -43 -22 -135 -23 -176 -2 -36 18 -86 73 -111
120 -10 20 -16 54 -16 93 1 183 173 303 330 230z m313 -23 l0 -47 28 26 c50
46 75 58 125 58 40 0 54 -5 76 -28 15 -15 30 -34 33 -42 4 -12 13 -8 39 16 48
44 71 54 126 54 51 -1 68 -10 96 -50 14 -21 17 -51 17 -200 l0 -175 -43 -19
c-23 -11 -45 -21 -49 -23 -3 -2 -8 74 -10 169 -4 206 -11 228 -79 228 -29 0
-47 -7 -65 -25 -24 -24 -25 -27 -23 -176 0 -83 -1 -152 -3 -153 -6 -6 -87 -46
-93 -46 -3 0 -5 80 -5 178 0 209 -4 222 -74 222 -29 0 -52 -7 -70 -21 l-26
-20 0 -160 0 -160 -46 -19 c-25 -11 -48 -20 -50 -20 -2 0 -4 100 -4 223 l0
222 38 17 c58 25 62 23 62 -29z m-3611 -344 c29 -24 91 -25 137 -3 l34 16 0
167 0 167 38 13 c20 8 43 16 50 18 17 7 17 -452 0 -511 -8 -26 -31 -60 -65
-94 -90 -90 -157 -103 -261 -51 l-62 31 25 41 c13 23 29 43 34 45 5 2 22 -11
38 -29 66 -75 177 -78 203 -6 5 16 10 65 10 108 0 62 -3 79 -14 79 -8 0 -16
-4 -18 -8 -1 -5 -23 -23 -47 -40 -55 -40 -118 -44 -167 -10 -55 38 -59 54 -59
236 l0 165 45 23 45 23 5 -181 c5 -174 6 -180 29 -199z m-3370 27 c17 -21 28
-42 26 -46 -3 -5 -23 -22 -44 -39 l-39 -31 -32 42 -31 43 42 37 c24 20 44 36
45 35 1 -1 16 -19 33 -41z m5790 -4 l30 -38 -46 -37 -46 -37 -28 38 c-16 20
-29 41 -29 46 0 12 73 75 82 70 3 -2 20 -21 37 -42z"/>
<path d="M2385 5690 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2411 5104 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6730 5850 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6697 5843 c6 -4 13 -22 16 -38 4 -27 5 -27 3 4 0 21 -7 36 -16 38
-10 3 -11 2 -3 -4z"/>
<path d="M3845 5399 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M1400 5480 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3455 5080 c-8 -13 -1 -24 14 -19 6 3 7 10 2 18 -6 10 -10 10 -16 1z"/>
<path d="M7081 4434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7741 3503 c7 -10 15 -20 16 -22 2 -2 3 3 3 11 0 9 -7 19 -16 22 -15
6 -15 4 -3 -11z"/>
<path d="M2041 4203 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"/>
<path d="M2010 4170 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7185 2910 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6461 797 c-72 -37 -88 -138 -35 -223 45 -74 84 -95 164 -92 l65 3 3
143 c3 141 2 143 -18 137 -14 -4 -30 2 -51 19 -34 29 -87 34 -128 13z"/>
<path d="M3320 645 c-32 -39 -5 -108 61 -153 40 -28 107 -27 152 2 25 17 27
23 27 87 0 70 -10 85 -32 50 -12 -19 -13 -19 -60 5 -55 28 -129 32 -148 9z"/>
<path d="M5392 648 c-22 -22 -14 -84 14 -118 53 -62 133 -77 197 -35 25 16 27
22 27 86 0 38 -3 69 -8 69 -4 0 -15 -9 -24 -20 -17 -19 -18 -19 -60 5 -47 27
-125 34 -146 13z"/>
<path d="M6912 648 c-22 -22 -14 -84 14 -118 53 -62 133 -77 197 -35 25 16 27
22 27 86 0 73 -5 81 -32 50 -16 -19 -17 -19 -64 5 -51 26 -122 32 -142 12z"/>
<path d="M7832 648 c-36 -36 5 -130 70 -164 38 -20 103 -15 141 11 25 16 27
22 27 86 0 38 -3 69 -7 69 -3 0 -15 -8 -25 -17 -18 -16 -21 -15 -64 5 -49 24
-123 29 -142 10z"/>
<path d="M9080 811 c-36 -9 -60 -64 -60 -134 0 -94 37 -159 112 -198 54 -28
94 -25 129 10 24 25 29 37 29 80 0 62 -31 147 -68 189 -16 17 -48 38 -71 47
-24 8 -44 15 -45 14 0 -1 -12 -4 -26 -8z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
